@use '../mixins/media-queries';

// prettier-ignore
@mixin display-utility-classes() {
  // display (block, inline, etc..)
  .bsz-display-none { display: none !important; }
  .bsz-display-mobile-none { @include media-queries.media-mobile () { display: none !important; } }
  .bsz-display-tablet-none { @include media-queries.media-tablet () { display: none !important; } }
  .bsz-display-desktop-none { @include media-queries.media-desktop () { display: none !important; } }

  .bsz-display-block { display: block !important; }
  .bsz-display-mobile-block { @include media-queries.media-mobile () { display: block !important; } }
  .bsz-display-tablet-block { @include media-queries.media-tablet () { display: block !important; } }
  .bsz-display-desktop-block { @include media-queries.media-desktop () { display: block !important; } }

  .bsz-display-inline { display: inline !important; }
  .bsz-display-mobile-inline { @include media-queries.media-mobile () { display: inline !important; } }
  .bsz-display-tablet-inline { @include media-queries.media-tablet () { display: inline !important; } }
  .bsz-display-desktop-inline { @include media-queries.media-desktop () { display: inline !important; } }

  .bsz-display-flex { display: flex !important; }
  .bsz-display-mobile-flex { @include media-queries.media-mobile () { display: flex !important; } }
  .bsz-display-tablet-flex { @include media-queries.media-tablet () { display: flex !important; } }
  .bsz-display-desktop-flex { @include media-queries.media-desktop () { display: flex !important; } }

  .bsz-display-grid { display: grid !important; }
  .bsz-display-mobile-grid { @include media-queries.media-mobile () { display: grid !important; } }
  .bsz-display-tablet-grid { @include media-queries.media-tablet () { display: grid !important; } }
  .bsz-display-desktop-grid { @include media-queries.media-desktop () { display: grid !important; } }

  .bsz-display-inline-block { display: inline-block !important; }
  .bsz-display-mobile-inline-block { @include media-queries.media-mobile () { display: inline-block !important; } }
  .bsz-display-tablet-inline-block { @include media-queries.media-tablet () { display: inline-block !important; } }
  .bsz-display-desktop-inline-block { @include media-queries.media-desktop () { display: inline-block !important; } }

  .bsz-display-inline-flex { display: inline-flex !important; }
  .bsz-display-mobile-inline-flex { @include media-queries.media-mobile () { display: inline-flex !important; } }
  .bsz-display-tablet-inline-flex { @include media-queries.media-tablet () { display: inline-flex !important; } }
  .bsz-display-desktop-inline-flex { @include media-queries.media-desktop () { display: inline-flex !important; } }

  .bsz-display-inline-grid { display: inline-grid !important; }
  .bsz-display-mobile-inline-grid { @include media-queries.media-mobile () { display: inline-grid !important; } }
  .bsz-display-tablet-inline-grid { @include media-queries.media-tablet () { display: inline-grid !important; } }
  .bsz-display-desktop-inline-grid { @include media-queries.media-desktop () { display: inline-grid !important; } }

  .bsz-flex-row { flex-direction: row !important; }
  .bsz-flex-row-reverse { flex-direction: row-reverse !important; }
  .bsz-flex-column { flex-direction: column !important; }
  .bsz-flex-column-reverse { flex-direction: column-reverse !important; }

  .bsz-flex-nowrap { flex-wrap: nowrap !important; }
  .bsz-flex-wrap { flex-wrap: wrap !important; }
  .bsz-flex-wrap-reverse { flex-wrap: reverse !important; }

  .bsz-justify-start { justify-content: flex-start !important; }
  .bsz-justify-end { justify-content: flex-end !important; }
  .bsz-justify-center { justify-content: center !important; }
  .bsz-justify-space-between { justify-content: space-between !important; }
  .bsz-justify-space-around { justify-content: space-around !important; }

  .bsz-align-items-start { align-items: flex-start !important; }
  .bsz-align-items-end { align-items: flex-end !important; }
  .bsz-align-items-center { align-items: center !important; }
  .bsz-align-items-baseline { align-items: baseline !important; }
  .bsz-align-items-stretch { align-items: stretch !important; }

  .bsz-align-content-start { align-content: flex-start !important; }
  .bsz-align-content-end { align-content: flex-end !important; }
  .bsz-align-content-center { align-content: center !important; }
  .bsz-align-content-space-between { align-content: space-between !important; }
  .bsz-align-content-space-around { align-content: space-around !important; }
  .bsz-align-content-stretch { align-content: stretch !important; }

  // visibility
  .bsz-visibility-hidden {
    visibility: hidden !important;
  }

  .bsz-visibility-visible {
    visibility: visible !important;
  }

  .bsz-visibility-collapse {
    visibility: collapse !important;
  }

  // Opacity
  .bsz-opacity-100 {
    opacity: 1 !important;
  }

  .bsz-opacity-75 {
    opacity: 0.75 !important;
  }

  .bsz-opacity-50 {
    opacity: 0.5 !important;
  }

  .bsz-opacity-25 {
    opacity: 0.25 !important;
  }

  .bsz-opacity-0 {
    opacity: 0 !important;
  }
}
