@use 'breakpoints' as breakpoints;
@use '@material/layout-grid/mdc-layout-grid';

@use '@angular/material' as mat;
@use '@basuiz/ui-elements/theming' as ui-elements with (
  $breakpoints: (
    desktop: breakpoints.$desktop,
    tablet: breakpoints.$tablet,
    phone: breakpoints.$mobile,
  )
);
@use '../../../libs/web-app/icons/basuiz-icons-mixin' as icons-mixin;

$typography: mat.define-typography-config(
  $font-family: #{'Open Sans, Helvetica Neue, sans-serif'},
  $display-4: mat.define-typography-level(6rem, 6rem, 300),
  $display-3: mat.define-typography-level(3.75rem, 3.75rem, 300),
  $display-2: mat.define-typography-level(3rem, 3rem, 400),
  $display-1: mat.define-typography-level(2.125rem, 2.125rem, 400),
  $headline: mat.define-typography-level(1.5rem, 2rem, 400),
  $title: mat.define-typography-level(1.25rem, 2rem, 500),
  $subheading-2: mat.define-typography-level(0.875rem, 1.375rem, 600),
  $subheading-1: mat.define-typography-level(1rem, 1.75rem, 600),
  $body-2: mat.define-typography-level(1rem, 1.25rem, 400),
  $body-1: mat.define-typography-level(0.875rem, 1.5rem, 400),
  $caption: mat.define-typography-level(0.75rem, 1.25rem, 400),
  $button: mat.define-typography-level(0.75rem, 1.25rem, 500),
  $input: mat.define-typography-level(0.875rem, 1.2, 400),
);

@include ui-elements.core($typography);
.bsz-web-app-theme-scope {
  @include icons-mixin.bsz-icons();
}

// Adaptations for the Phrase toolbar from UI-SDK to work with the portal
body.bsz-jungfrau--translator-mode-active {
  $ui-sdk-phrase-bar-height: 48px;
  $phrase-in-context-editor-bar-height: 52px;
  $portal-app-bar-height: 65px;
  $combined-top-bars-height: $ui-sdk-phrase-bar-height + $portal-app-bar-height;
  $combined-all-bars-height: $combined-top-bars-height + $phrase-in-context-editor-bar-height;

  padding-top: initial !important; // remove padding added by UI-SDK on the body

  bsz-phrase-toolbar {
    position: fixed !important;
    z-index: 2; // must be a value below the mat-sidenav of the portal

    mat-toolbar {
      // Make the phrase-bar fit in mobile screens
      @media (max-width: 1160px) {
        & > span {
          display: none;
        }
        & > mat-form-field {
          max-width: 80px;
        }
      }
    }
  }
  bsz-web-app-portal {
    .bsz-portal--app-bar {
      top: $ui-sdk-phrase-bar-height !important; // move app-bar below the phrase-bar
    }
    .bsz-portal--content-boundary {
      margin-bottom: $phrase-in-context-editor-bar-height !important; // adapt the margin to fit bottom bar
    }
    .footer-fixed-container {
      margin-bottom: $phrase-in-context-editor-bar-height !important; // adapt the margin to fit bottom bar
    }
    mat-sidenav-container {
      mat-sidenav {
        margin-top: $combined-top-bars-height !important; // adapt the margin to fit both top bars
        margin-bottom: $phrase-in-context-editor-bar-height !important; // adapt the margin to fit bottom bar
        min-height: calc(100vh - #{$combined-all-bars-height}) !important; // adapt to fit all bars
      }
      margin-top: $combined-top-bars-height !important; // adapt the margin to fit both top bars
      margin-bottom: $phrase-in-context-editor-bar-height !important; // adapt the margin to fit bottom bar
      min-height: calc(100vh - #{$combined-all-bars-height}) !important; // adapt to fit all bars
    }
  }
  .dev-tools {
    bottom: $phrase-in-context-editor-bar-height; // move dev-tools above bottom bar
  }
}
