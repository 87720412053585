@use '../mixins/spacing';

// prettier-ignore
@mixin spacing-utility-classes() {
  // margins
  .bsz-margin-auto {
    margin: auto !important;
  }

  .bsz-margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .bsz-margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  @for $i from 0 through 12 {
    .bsz-margin-#{$i} { @include spacing.margin($i); }
    .bsz-margin-mobile-#{$i} { @include spacing.margin($i, mobile); }
    .bsz-margin-tablet-#{$i} { @include spacing.margin($i, tablet); }
    .bsz-margin-desktop-#{$i} { @include spacing.margin($i, desktop); }

    .bsz-margin-x#{$i} { @include spacing.margin-x($i); }
    .bsz-margin-mobile-x#{$i} { @include spacing.margin-x($i, mobile); }
    .bsz-margin-tablet-x#{$i} { @include spacing.margin-x($i, tablet); }
    .bsz-margin-desktop-x#{$i} { @include spacing.margin-x($i, desktop); }

    .bsz-margin-y#{$i} { @include spacing.margin-y($i); }
    .bsz-margin-mobile-y#{$i} { @include spacing.margin-y($i, mobile); }
    .bsz-margin-tablet-y#{$i} { @include spacing.margin-y($i, tablet); }
    .bsz-margin-desktop-y#{$i} { @include spacing.margin-y($i, desktop); }

    .bsz-margin-l#{$i} { @include spacing.margin-l($i); }
    .bsz-margin-mobile-l#{$i} { @include spacing.margin-l($i, mobile); }
    .bsz-margin-tablet-l#{$i} { @include spacing.margin-l($i, tablet); }
    .bsz-margin-desktop-l#{$i} { @include spacing.margin-l($i, desktop); }

    .bsz-margin-r#{$i} { @include spacing.margin-r($i); }
    .bsz-margin-mobile-r#{$i} { @include spacing.margin-r($i, mobile); }
    .bsz-margin-tablet-r#{$i} { @include spacing.margin-r($i, tablet); }
    .bsz-margin-desktop-r#{$i} { @include spacing.margin-r($i, desktop); }

    .bsz-margin-t#{$i} { @include spacing.margin-t($i); }
    .bsz-margin-mobile-t#{$i} { @include spacing.margin-t($i, mobile); }
    .bsz-margin-tablet-t#{$i} { @include spacing.margin-t($i, tablet); }
    .bsz-margin-desktop-t#{$i} { @include spacing.margin-t($i, desktop); }

    .bsz-margin-b#{$i} { @include spacing.margin-b($i); }
    .bsz-margin-mobile-b#{$i} { @include spacing.margin-b($i, mobile); }
    .bsz-margin-tablet-b#{$i} { @include spacing.margin-b($i, tablet); }
    .bsz-margin-desktop-b#{$i} { @include spacing.margin-b($i, desktop); }
  }

  // paddings
  @for $i from 0 through 12 {
    .bsz-padding-#{$i} { @include spacing.padding($i); }
    .bsz-padding-mobile-#{$i} { @include spacing.padding($i, mobile); }
    .bsz-padding-tablet-#{$i} { @include spacing.padding($i, tablet); }
    .bsz-padding-desktop-#{$i} { @include spacing.padding($i, desktop); }

    .bsz-padding-x#{$i} { @include spacing.padding-x($i); }
    .bsz-padding-mobile-x#{$i} { @include spacing.padding-x($i, mobile); }
    .bsz-padding-tablet-x#{$i} { @include spacing.padding-x($i, tablet); }
    .bsz-padding-desktop-x#{$i} { @include spacing.padding-x($i, desktop); }

    .bsz-padding-y#{$i} { @include spacing.padding-y($i); }
    .bsz-padding-mobile-y#{$i} { @include spacing.padding-y($i, mobile); }
    .bsz-padding-tablet-y#{$i} { @include spacing.padding-y($i, tablet); }
    .bsz-padding-desktop-y#{$i} { @include spacing.padding-y($i, desktop); }

    .bsz-padding-l#{$i} { @include spacing.padding-l($i); }
    .bsz-padding-mobile-l#{$i} { @include spacing.padding-l($i, mobile); }
    .bsz-padding-tablet-l#{$i} { @include spacing.padding-l($i, tablet); }
    .bsz-padding-desktop-l#{$i} { @include spacing.padding-l($i, desktop); }

    .bsz-padding-r#{$i} { @include spacing.padding-r($i); }
    .bsz-padding-mobile-r#{$i} { @include spacing.padding-r($i, mobile); }
    .bsz-padding-tablet-r#{$i} { @include spacing.padding-r($i, tablet); }
    .bsz-padding-desktop-r#{$i} { @include spacing.padding-r($i, desktop); }

    .bsz-padding-t#{$i} { @include spacing.padding-t($i); }
    .bsz-padding-mobile-t#{$i} { @include spacing.padding-t($i, mobile); }
    .bsz-padding-tablet-t#{$i} { @include spacing.padding-t($i, tablet); }
    .bsz-padding-desktop-t#{$i} { @include spacing.padding-t($i, desktop); }

    .bsz-padding-b#{$i} { @include spacing.padding-b($i); }
    .bsz-padding-mobile-b#{$i} { @include spacing.padding-b($i, mobile); }
    .bsz-padding-tablet-b#{$i} { @include spacing.padding-b($i, tablet); }
    .bsz-padding-desktop-b#{$i} { @include spacing.padding-b($i, desktop); }
  }
}
