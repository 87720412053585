@use '@angular/material' as mat;
@use '../mixins/media-queries';
@use '../theme/css-variables' as theme;
@use 'sass:map';

// prettier-ignore
@mixin text-utility-classes($typography-config) {
  // text-style (bold, italic, etc...)
  .bsz-text-bold { font-weight: map.get(map.get(theme.$theme, foreground), bold-weight) !important; }
  .bsz-text-italic { font-style: italic !important; }

  .bsz-text-normal {
    font-style: normal !important;
    font-weight: normal !important;
  }

  // Text transform
  .bsz-text-uppercase { text-transform: uppercase !important; }
  .bsz-text-lowercase { text-transform: lowercase !important; }
  .bsz-text-capitalize { text-transform: capitalize !important; }

  .bsz-text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bsz-text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
  }

  .bsz-text-nowrap {
    word-break: keep-all !important;
    white-space: nowrap !important;
  }

  // text-alignment
  .bsz-text-left { text-align: left !important; }
  .bsz-text-mobile-left { @include media-queries.media-mobile () { text-align: left !important; } }
  .bsz-text-tablet-left { @include media-queries.media-tablet () { text-align: left !important; } }
  .bsz-text-desktop-left { @include media-queries.media-desktop () { text-align: left !important; } }

  .bsz-text-right { text-align: right !important; }
  .bsz-text-mobile-right { @include media-queries.media-mobile () { text-align: right !important; } }
  .bsz-text-tablet-right { @include media-queries.media-tablet () { text-align: right !important; } }
  .bsz-text-desktop-right { @include media-queries.media-desktop () { text-align: right !important; } }

  .bsz-text-center { text-align: center !important; }
  .bsz-text-mobile-center { @include media-queries.media-mobile () { text-align: center !important; } }
  .bsz-text-tablet-center { @include media-queries.media-tablet () { text-align: center !important; } }
  .bsz-text-desktop-center { @include media-queries.media-desktop () { text-align: center !important; } }

  .bsz-text-justify { text-align: justify; }
  .bsz-text-mobile-justify { @include media-queries.media-mobile () { text-align: justify !important; } }
  .bsz-text-tablet-justify { @include media-queries.media-tablet () { text-align: justify !important; } }
  .bsz-text-desktop-justify { @include media-queries.media-desktop () { text-align: justify !important; } }

  // Text Decoration
  .bsz-text-underline { text-decoration: underline !important; }
  .bsz-text-line-through { text-decoration: line-through !important; }
  .bsz-text-no-underline { text-decoration: none !important; }

  // Typography
  // https://material.io/design/typography/the-type-system.html
  .bsz-title-1 {
    font-size: mat.font-size($typography-config, display-4) !important;
    font-weight: mat.font-weight($typography-config, display-4);
    line-height: mat.line-height($typography-config, display-4) !important;
    font-family: mat.font-family($typography-config, display-4) !important;
  }

  .bsz-title-2 {
    font-size: mat.font-size($typography-config, display-3) !important;
    font-weight: mat.font-weight($typography-config, display-3);
    line-height: mat.line-height($typography-config, display-3) !important;
    font-family: mat.font-family($typography-config, display-3) !important;
  }

  .bsz-title-3 {
    font-size: mat.font-size($typography-config, display-2) !important;
    font-weight: mat.font-weight($typography-config, display-2);
    line-height: mat.line-height($typography-config, display-2) !important;
    font-family: mat.font-family($typography-config, display-2) !important;
  }

  .bsz-title-4 {
    font-size: mat.font-size($typography-config, display-1) !important;
    font-weight: mat.font-weight($typography-config, display-1);
    line-height: mat.line-height($typography-config, display-1) !important;
    font-family: mat.font-family($typography-config, display-1) !important;
  }

  .bsz-title-5 {
    font-size: mat.font-size($typography-config, headline) !important;
    font-weight: mat.font-weight($typography-config, headline);
    line-height: mat.line-height($typography-config, headline) !important;
    font-family: mat.font-family($typography-config, headline) !important;
  }

  .bsz-title-6 {
    font-size: mat.font-size($typography-config, title) !important;
    font-weight: mat.font-weight($typography-config, title);
    line-height: mat.line-height($typography-config, title) !important;
    font-family: mat.font-family($typography-config, title) !important;
  }

  .bsz-subtitle-1 {
    font-size: mat.font-size($typography-config, subheading-2) !important;
    font-weight: mat.font-weight($typography-config, subheading-2);
    line-height: mat.line-height($typography-config, subheading-2) !important;
    font-family: mat.font-family($typography-config, subheading-2) !important;
  }

  .bsz-subtitle-2 {
    font-size: mat.font-size($typography-config, subheading-1) !important;
    font-weight: mat.font-weight($typography-config, subheading-1);
    line-height: mat.line-height($typography-config, subheading-1) !important;
    font-family: mat.font-family($typography-config, subheading-1) !important;
  }

  .bsz-body-1 {
    font-size: mat.font-size($typography-config, body-1) !important;
    font-weight: mat.font-weight($typography-config, body-1);
    line-height: mat.line-height($typography-config, body-1) !important;
    font-family: mat.font-family($typography-config, body-1) !important;
  }

  .bsz-body-2 {
    font-size: mat.font-size($typography-config, body-2) !important;
    font-weight: mat.font-weight($typography-config, body-2);
    line-height: mat.line-height($typography-config, body-2) !important;
    font-family: mat.font-family($typography-config, body-2) !important;
  }

  .bsz-caption {
    font-size: mat.font-size($typography-config, caption) !important;
    font-weight: mat.font-weight($typography-config, caption);
    line-height: mat.line-height($typography-config, caption) !important;
    font-family: mat.font-family($typography-config, caption) !important;
  }

  // this class is an exception outside of the standard typography config
  .bsz-article {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.75rem !important;
    font-family: inherit;
  }
}
