// Utilities for controlling the cursor style when hovering over an element.
@mixin cursor-utility-classes() {
  .bsz-cursor-auto {
    cursor: auto !important;
  }

  .bsz-cursor-default {
    cursor: default !important;
  }

  .bsz-cursor-pointer {
    cursor: pointer !important;
  }

  .bsz-cursor-wait {
    cursor: wait !important;
  }

  .bsz-cursor-text {
    cursor: text !important;
  }

  .bsz-cursor-move {
    cursor: move !important;
  }

  .bsz-cursor-not-allowed {
    cursor: not-allowed !important;
  }
}
