@use './media-queries';
@use 'sass:map';

$bsz-spacing-scale: 4px;

// Margins

/// Apply margin to all sides
@mixin margin($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      margin: $level * $bsz-spacing-scale !important;
    }
  } @else {
    margin: $level * $bsz-spacing-scale !important;
  }
}

/// Apply margin vertically
@mixin margin-x($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      margin-left: $level * $bsz-spacing-scale !important;
      margin-right: $level * $bsz-spacing-scale !important;
    }
  } @else {
    margin-left: $level * $bsz-spacing-scale !important;
    margin-right: $level * $bsz-spacing-scale !important;
  }
}

/// Apply margin horizontally
@mixin margin-y($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      margin-top: $level * $bsz-spacing-scale !important;
      margin-bottom: $level * $bsz-spacing-scale !important;
    }
  } @else {
    margin-top: $level * $bsz-spacing-scale !important;
    margin-bottom: $level * $bsz-spacing-scale !important;
  }
}

/// Apply margin left
@mixin margin-l($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      margin-left: $level * $bsz-spacing-scale !important;
    }
  } @else {
    margin-left: $level * $bsz-spacing-scale !important;
  }
}

/// Apply margin right
@mixin margin-r($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      margin-right: $level * $bsz-spacing-scale !important;
    }
  } @else {
    margin-right: $level * $bsz-spacing-scale !important;
  }
}

/// Apply margin top
@mixin margin-t($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      margin-top: $level * $bsz-spacing-scale !important;
    }
  } @else {
    margin-top: $level * $bsz-spacing-scale !important;
  }
}

/// Apply margin bottom
@mixin margin-b($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      margin-bottom: $level * $bsz-spacing-scale !important;
    }
  } @else {
    margin-bottom: $level * $bsz-spacing-scale !important;
  }
}

// Paddings

/// Apply padding to all sides
@mixin padding($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      padding: $level * $bsz-spacing-scale !important;
    }
  } @else {
    padding: $level * $bsz-spacing-scale !important;
  }
}

/// Apply padding horizontally
@mixin padding-x($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      padding-left: $level * $bsz-spacing-scale !important;
      padding-right: $level * $bsz-spacing-scale !important;
    }
  } @else {
    padding-left: $level * $bsz-spacing-scale !important;
    padding-right: $level * $bsz-spacing-scale !important;
  }
}

/// Apply padding vertically
@mixin padding-y($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      padding-top: $level * $bsz-spacing-scale !important;
      padding-bottom: $level * $bsz-spacing-scale !important;
    }
  } @else {
    padding-top: $level * $bsz-spacing-scale !important;
    padding-bottom: $level * $bsz-spacing-scale !important;
  }
}

/// Apply padding left
@mixin padding-l($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      padding-left: $level * $bsz-spacing-scale !important;
    }
  } @else {
    padding-left: $level * $bsz-spacing-scale !important;
  }
}

/// Apply padding right
@mixin padding-r($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      padding-right: $level * $bsz-spacing-scale !important;
    }
  } @else {
    padding-right: $level * $bsz-spacing-scale !important;
  }
}

/// Apply padding top
@mixin padding-t($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      padding-top: $level * $bsz-spacing-scale !important;
    }
  } @else {
    padding-top: $level * $bsz-spacing-scale !important;
  }
}

/// Apply padding bottom
@mixin padding-b($level, $device: null) {
  @if ($device) {
    @include media-queries.media($device) {
      padding-bottom: $level * $bsz-spacing-scale !important;
    }
  } @else {
    padding-bottom: $level * $bsz-spacing-scale !important;
  }
}
