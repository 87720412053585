$pixel-scale: 4px;

@mixin layout-utility-classes() {
  // # widths #
  .bsz-width-auto {
    width: auto;
  }

  // by percent
  @for $i from 1 through 100 {
    @if $i % 5 == 0 {
      .bsz-width-#{$i}pct {
        width: #{$i + '%'} !important;
      }
    }
  }

  // by absolute units
  @for $i from 1 through 12 {
    .bsz-width-#{$i} {
      width: $i * $pixel-scale;
    }
  }
}
