// prettier-ignore
@mixin position-utility-classes() {
  // float
  .bsz-float-left { float: left !important; }
  .bsz-float-right { float: right !important; }
  .bsz-float-none { float: none !important; }

  // position (relative, fixed, etc...)
  .bsz-position-static { position: static !important; }
  .bsz-position-relative { position: relative !important; }
  .bsz-position-fixed { position: fixed !important; }
  .bsz-position-absolute { position: absolute !important; }

  // Fixed position around the screen helpers
  .bsz-fixed-top-left {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }

  .bsz-fixed-top-center {
    position: fixed !important;
    top: 0 !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
  }

  .bsz-fixed-top-right {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
  }

  .bsz-fixed-bottom-left {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .bsz-fixed-bottom-center {
    position: fixed !important;
    bottom: 0 !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
  }

  .bsz-fixed-bottom-right {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
  }
}
