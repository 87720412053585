@use './../variables';
@use 'sass:map';

$tablet: map.get(variables.$breakpoints, tablet);
$desktop: map.get(variables.$breakpoints, desktop);

@mixin media-mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin media-desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin media($device) {
  @if ($device == mobile) {
    @include media-mobile() {
      @content;
    }
  }
  @if ($device == tablet) {
    @include media-tablet() {
      @content;
    }
  }
  @if ($device == desktop) {
    @include media-desktop() {
      @content;
    }
  }
  @if ($device != mobile and $device != tablet and $device != desktop) {
    @error "Unknown device '#{$device}'. Valid options are: mobile, tablet, desktop";
  }
}
