/**
 * This file mimics the ./icons/basuiz-icons.css to allow application developers to scope icons.
 * Its content needs to be kept aligned with the other file.
 */

@font-face {
  font-family: 'Basuiz-Icons-Outlined';
  font-style: normal;
  font-weight: 400;
  src: local('fonts/Basuiz-Icons-Outlined'), url('./Basuiz-Icons-Outlined.woff2') format('woff2'),
    url('./Basuiz-Icons-Outlined.woff') format('woff'), url('./Basuiz-Icons-Outlined.ttf') format('truetype');
}

@mixin bsz-icons() {
  // This act as a ternary operator
  // It will add a parent class to material-icons if provided
  #{if(&, '&', '')} .material-icons {
    font-family: 'Basuiz-Icons-Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}
