@use '@angular/material' as mat;
@use './../ui-elements/bsz-stepper';
@use './../ui-elements/bsz-highlight-text';
@use './../ui-elements/bsz-badge';
@use '../utilities/index' as utilities;
@use '../utilities/colors' as color-utilities;
@use '../variables' as public-variables;
@use './variables' as private-variables;
@use './reboot';
@use './css-variables';
@use './functions';
@use './builder';
@use 'sass:map';
@use 'sass:string';
@use 'sass:meta';

// Mixin that includes all the structural style without any theme
@mixin core($typography-config: null, $reboot: true) {
  @if ($typography-config == null) {
    $typography-config: private-variables.$typography;
  }

  @include _typography-css-properties($typography-config);
  @include _breakpoints-css-properties();
  @include mat.core($typography-config);
  @include utilities.all-utility-classes($typography-config);

  @if $reboot == true {
    @at-root {
      @include reboot.reboot($typography-config);
    }
  }
}

// Mixin that includes all angular material and ui-elements theme, plus overrides and additional colors
@mixin theme($app-theme: null) {
  @if ($app-theme == null) {
    $app-theme: builder.define-light-theme();
  }

  @include _all-components-themes($app-theme);
  @include _css-properties($app-theme);
  @include color-utilities.color-utility-classes($app-theme);
}

@mixin _css-properties($theme) {
  $css-props: functions.generate-css-properties(css-variables.$theme, $theme);

  // this acts like ternary operator: & ? '&' : ':root'
  // "&" represents the parent selector, if there is any
  #{if(&, '&', ':root')} {
    @each $prop, $value in $css-props {
      #{$prop}: $value;
    }
  }
}

@mixin _typography-css-properties($typography) {
  $css-props: functions.generate-css-properties(css-variables.$typography, $typography);

  #{if(&, '&', ':root')} {
    @each $prop, $value in $css-props {
      @if (meta.type-of($value) == string) {
        // We unquote the strings because it produces invalid css for the font-family
        // for example "'Source Sans Pro', sans-serif" while we need 'Source Sans Pro', sans-serif
        #{$prop}: string.unquote($value);
      } @else {
        #{$prop}: $value;
      }
    }
  }
}

@mixin _breakpoints-css-properties() {
  // we need the :root selector here, otherwise sass will apply the properties to the root of the document but
  // without a parent selector, making the properties invalid.
  @at-root :root {
    --bsz-theme-breakpoint-desktop: #{map.get(public-variables.$breakpoints, 'desktop')};
    --bsz-theme-breakpoint-tablet: #{map.get(public-variables.$breakpoints, 'tablet')};
    --bsz-theme-breakpoint-mobile: #{map.get(public-variables.$breakpoints, 'mobile')};
  }
}

/** Add colored outline for stroked buttons instead of a gray one */
@mixin _outline-buttons($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);

  [mat-stroked-button].mat-stroked-button:not([disabled]).mat-primary {
    border-color: mat.get-color-from-palette($primary);
  }

  [mat-stroked-button].mat-stroked-button:not([disabled]).mat-accent {
    border-color: mat.get-color-from-palette($accent);
  }

  [mat-stroked-button].mat-stroked-button:not([disabled]).mat-warn {
    border-color: mat.get-color-from-palette($warn);
  }
}

/**
 * This mixin resets the values of the properties that have been identified
 * that cause issues and creates a patched version of the mat-form-field-theme().
 *
 * Because we allow all variables in the theme to be customized, a potential
 * change of colors in foreground and background palettes can cause unexpected
 * issues since some calculation take place inside mat-form-field-theme().
 * --------------------------------------------------------------------
 * - foreground.divider
 * Reset the color of the divider to black (or white if dark theme)
 * so that the mat-form-field-theme will pick the correct colors for
 * the outlines.
 *
 * Form field theme extracts an opacity from the divider color
 * (12% idle, 87% hover) and if that color is changed, the
 * outline color will be too light.
 * --------------------------------------------------------------------
 * - foreground.secondary-text
 * Reset the color of secondary-text to its defaults from material theme.
 *
 * Form field theme extracts an opacity from the secondary-text to use in the labels
 * in case the color is customized this can cause unexpected accessibility issues
 * regarding the contrast of the text
 */
@mixin _mat-form-field-patch-theme($theme) {
  $is-dark: map.get($theme, is-dark);
  $foreground: map.get($theme, foreground);
  $form-field-divider-color: if($is-dark, rgba(white, 0.12), rgba(black, 0.12));
  $secondary-text: if(
    $is-dark,
    map.get(mat.$dark-theme-foreground-palette, 'secondary-text'),
    map.get(mat.$light-theme-foreground-palette, 'secondary-text')
  );

  // Reset the divider color and secondary-text
  $foreground-patched: map.merge(
    $foreground,
    (
      divider: $form-field-divider-color,
      secondary-text: $secondary-text,
    )
  );

  // Merge the new foreground ot the theme
  // prettier-ignore
  $theme-patched: map.merge(
    $theme,
    (foreground: $foreground-patched)
  );

  @include mat.form-field-theme($theme-patched);
}

/** Angular material styles and overrides */
@mixin _all-components-themes($theme) {
  @include bsz-stepper.theme($theme);
  @include bsz-highlight-text.theme($theme);
  @include bsz-badge.theme($theme);
  @include _outline-buttons($theme);
  @include _mat-form-field-patch-theme($theme);

  // Angular material
  @include mat.core-theme($theme);
  @include mat.autocomplete-theme($theme);
  @include mat.badge-theme($theme);
  @include mat.bottom-sheet-theme($theme);
  @include mat.button-theme($theme);
  @include mat.button-toggle-theme($theme);
  @include mat.card-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.chips-theme($theme);
  @include mat.table-theme($theme);
  @include mat.datepicker-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.divider-theme($theme);
  @include mat.expansion-theme($theme);
  @include mat.grid-list-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.input-theme($theme);
  @include mat.list-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.paginator-theme($theme);
  @include mat.progress-bar-theme($theme);
  @include mat.progress-spinner-theme($theme);
  @include mat.radio-theme($theme);
  @include mat.select-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.slide-toggle-theme($theme);
  @include mat.slider-theme($theme);
  @include mat.stepper-theme($theme);
  @include mat.sort-theme($theme);
  @include mat.tabs-theme($theme);
  @include mat.toolbar-theme($theme);
  @include mat.tooltip-theme($theme);
  @include mat.tree-theme($theme);
  @include mat.snack-bar-theme($theme);
}
