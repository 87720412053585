// stylelint-disable property-no-vendor-prefix
// prettier-ignore
@mixin input-utility-classes() {
  .bsz-input-no-spin {
    /* Webkit based browsers */
    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
